import { provideHttpClient, withJsonpSupport, withFetch, withInterceptors, HttpClient } from '@angular/common/http';
import {
  ApplicationConfig,
  enableProdMode,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withViewTransitions, withInMemoryScrolling } from '@angular/router';
import { createTranslateLoader, AppCustomTranslateParser } from '@core/custom-translate-parser';
import { authInterceptor } from '@core/interceptors/auth.interceptor';
import { httpErrorInterceptor } from '@core/interceptors/error-interceptor';
import { KeycloakService } from '@core/services/auth/keycloak.service';
import { environment } from '@environments/environment';
import { TranslateLoader, TranslateParser, provideTranslateService } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import appRoutes from 'src/app/app-routes';
import { defineCustomElements as tegel } from '@scania/tegel/loader';
import { defineCustomElements as scope } from '@myscania/scope/dist/loader';

tegel(window);

scope();

if (environment.production) {
  enableProdMode();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideAppInitializer(() => {
      const keyclaokService = inject(KeycloakService);
      return keyclaokService.init();
    }),
    provideRouter(appRoutes, withViewTransitions(), withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    provideHttpClient(withJsonpSupport(), withFetch(), withInterceptors([httpErrorInterceptor, authInterceptor])),
    importProvidersFrom(
      ToastrModule.forRoot({
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }),
      BrowserAnimationsModule,
    ),

    provideTranslateService({
      defaultLanguage: 'en-GB',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useClass: AppCustomTranslateParser,
      },
    }),
  ],
};
