<div class="mode-wrapper {{ uiMode() }}">
  <div class="mode-variant-wrapper {{ uiVariant() }}">
    <scope-theme></scope-theme>
    <app-stencil-navbar id="scope-navbar-top-menu"></app-stencil-navbar>
    <sss-navbar-side-menu
      id="scope-navbar-side-menu"
      app-stencil-sidemenu
      collapsible="true"
      expanded="true"
      (navbarSideMenuExpandedStateChanged)="sideMenuStateChanged($event)"
    >
    </sss-navbar-side-menu>

    <main>
      <router-outlet> </router-outlet>
      @if (showNotificationPopup()) {
        <app-notification-popup [message]="message" (closePopup)="closePopup()"></app-notification-popup>
      }
      <scp-cookie-settings id="cookie-settings" privacyurl="#/privacy" culturecode="{{ setApplicatonLanguage() }}">
        <div style="position: fixed; bottom: 0; z-index: 1999999">
          <scp-cookie-footer culturecode="{{ setApplicatonLanguage() }}" hide-cookie-button="true"></scp-cookie-footer>
        </div>
      </scp-cookie-settings>
    </main>
  </div>

  <div id="widget-container">
    @if (viewZendesk()) {
      @defer (on timer(10000)) {
        @if (languageCode().length > 0) {
          <app-zendesk [languageCode]="languageCode()" [fdGwToken]="fdGwToken()"> </app-zendesk>
        }
      }
    }
  </div>
</div>
