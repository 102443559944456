import { inject } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

export type CHSErrorMessage = {
  correlationId: string;
  errorMessage: string;
  stackTrace: any;
};

export function generateErrorMessage(error: CHSErrorMessage): string {
  let errorMsg = `Error message: ${error.errorMessage} `;
  let correlationID = `Correlation ID: ${error.correlationId} `;

  return `${errorMsg}\n${correlationID}`;
}

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const toastr = inject(ToastrService);
  const exceptionUrls = ['/signupNewCustomer'];
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage = error.message;
      if (!exceptionUrls.some((url) => req.url.includes(url))) {
        let errObject = error.error as CHSErrorMessage;
        let errorTitle = `Server Error Code: ${error.status}, Message: ${error.message}`;
        errorMessage = generateErrorMessage(errObject);
        toastr.error(errorMessage, errorTitle);
      }
      return throwError(() => errorMessage);
    }),
  );
};
