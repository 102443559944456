import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  inject,
  OnInit,
  signal,
  Signal,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthSsoService } from '@core/services/auth/auth-sso.service';
import { FeatureToggleService } from '@core/services/auth/feature-toggle.service';
import { StencilNavbarComponent } from '@shared/components/stencil-navbar/stencil-navbar.component';
import { StencilSidemenuComponent } from '@shared/components/stencil-sidemenu/stencil-sidemenu.component';
import { environment } from '@environments/environment';
import { getMessaging, onMessage } from '@firebase/messaging';
import { initializeApp } from '@firebase/app';
import { NotificationPopupComponent } from '@shared/components/notification-popup/notification-popup.component';
import { DatadogService } from '@core/services/datadog.service';
import { CheckforUpdateService } from '@core/services/checkfor-update.service';
import { SharedService } from '@shared/services/shared.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ZendeskComponent } from '@shared/components/zendesk/zendesk.component';

declare global {
  interface Window {
    signOut: any;
    keycloak: any;
    config: any;
  }
}
initializeApp(environment.firebase);

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterModule,
    StencilNavbarComponent,
    StencilSidemenuComponent,
    CommonModule,
    NotificationPopupComponent,
    TranslateModule,
    ZendeskComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private sharedService = inject(SharedService);

  isDarkMode = this.sharedService.modeSignal() === 'tds-mode-dark' ? true : false;
  isPrimaryModeVariant = this.sharedService.modeVariantSignal() === 'tds-mode-variant-primary' ? true : false;
  languageCode = this.sharedService.languageCode;

  hanldeUIModeChange(event: any) {
    this.isDarkMode = event.detail.checked;
    this.sharedService.changeUIMode(this.isDarkMode);
  }

  handleVariantModeChange(event: any) {
    this.isPrimaryModeVariant = event.detail.checked;
    this.sharedService.changeModeVariant(this.isPrimaryModeVariant);
  }

  uiMode = this.sharedService.modeSignal;
  uiVariant = this.sharedService.modeVariantSignal;

  fdGwToken = this.authSsoService.fdGWTokenSignal;
  showSideMenu: boolean = true;
  showSideMenu$: Observable<boolean>;

  setApplicatonLanguage = computed(() => {
    if (this.languageCode().length > 0) {
      this.translate.use(this.languageCode());
      return this.languageCode();
    }
    return null;
  });

  showNotificationPopup = signal<boolean>(false);
  message: any = null;

  version = environment.appVersion;
  envName = environment.name;

  showSideMenuSignal: Signal<any>;
  zendeskFeatureToggled: Signal<boolean> = this.featureToggleService.zendeskFeatureToggled;

  constructor(
    private authSsoService: AuthSsoService,
    private translate: TranslateService,
    private featureToggleService: FeatureToggleService,
    private dataDog: DatadogService,
    private updateService: CheckforUpdateService,
  ) {
    dataDog.verifyDatadogLoggingRulesAndLog();

    window.addEventListener('navbarAccountLogOut', () => {
      this.logout();
    });
  }

  sideMenuStateChanged(event: any) {
    // event.detail is the state of the side menu
    this.dataDog.sidemenuChanged(event.detail);
  }

  ngOnInit() {
    console.log(
      `%c charging-services version ${this.version}`,
      ' background: linear-gradient(to right, #2B70D3, #000000)',
    );

    this.requestPermission();
    this.listen();

    this.updateService.checkVersionUpdate();
    this.setDefaultLanguage();
  }

  setDefaultLanguage() {
    this.translate.setDefaultLang('en-GB');
  }

  logout() {
    const local = window.location.href.includes('localhost');
    this.authSsoService.logout('UserLoggedout', local);
    this.dataDog.endSession();
  }

  requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.log('Notification permission not granted');
      }
    });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      if (payload?.data) {
        window.localStorage.setItem('departureId', payload.data['departureId']);
      }
      this.message = payload;
      this.showNotificationPopup.set(true);
    });
  }

  closePopup() {
    this.showNotificationPopup.set(false);
  }
}
