import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/auth/user.service';
import { firstValueFrom } from 'rxjs';

export const onboardingGuard: CanActivateFn = async (route, state) => {
  const userService = inject(UserService);
  const router = inject(Router);

  const requiredUserPermissions = route.data['requiredUserPermissions'];
  const redirectUrl = route.data['redirectUrl'];
  const currentURL = state.url;
  try {
    const onboarded = await firstValueFrom(userService.getCustomerOnboardedStatus());
    return handleOnboardingNavigation(onboarded, requiredUserPermissions, currentURL, redirectUrl, router);
  } catch (error) {
    console.error('Error in onboarding guard:', error);
    router.navigate([redirectUrl]);
    return false;
  }
};

function handleOnboardingNavigation(
  onboarded: boolean,
  requiredUserPermissions: any,
  currentURL: string,
  redirectUrl: string,
  router: Router,
): boolean {
  if (onboarded && currentURL.startsWith('/onboarding')) {
    console.log('CUSTOMER ALREADY ONBOARDED, REDIRECTING TO CHARGING MANAGEMENT URL', redirectUrl);
    router.navigate([redirectUrl]);
    return false;
  }

  if (!onboarded && currentURL.startsWith('/charging-management')) {
    console.log('%c CUSTOMER NOT ONBOARDED, REDIRECTING TO ONBOARDING URL', 'background: red;');
    router.navigate(['/onboarding']);
    return false;
  }

  if (onboarded && requiredUserPermissions) {
    console.log('%cONBOARDED AND HAS ACCESS TO CHARGING MANAGEMENT!', 'background: green; font-weight: bold;');
    return true;
  }

  if (!onboarded && currentURL.startsWith('/onboarding')) {
    console.log('%c CUSTOMER NOT ONBOARDED, ONBOARDING FLOW STARTED', 'background: blue;');
    return true;
  }

  return false;
}
