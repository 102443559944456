import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthSsoService } from '@core/services/auth/auth-sso.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedService } from '@shared/services/shared.service';

@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-stencil-navbar',
  templateUrl: './stencil-navbar.component.html',
  styleUrls: ['./stencil-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StencilNavbarComponent implements OnInit {
  private authSsoService = inject(AuthSsoService);
  languageCode = inject(SharedService).languageCode;

  environmentName: string = '';
  isLoggedIn = this.authSsoService.isLoggedIn;

  ngOnInit(): void {
    this.environmentName = environment.name;
  }

  goToPrivacyPage() {
    const privacyUrl = environment.myScania + '/privacy';
    window.open(privacyUrl, '_blank');
  }
}
