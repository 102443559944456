<tds-toast
  variant="information"
  header=" {{ message.data.title }}"
  subheader="{{ message.data.body }}"
  (tdsClose)="closeModal()"
>
  <tds-link slot="actions">
    <a href="{{ notificationUrl }}" (click)="closeModal()"> {{ 'DS_ViewMore' | translate }}</a>
  </tds-link>
</tds-toast>
