import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { flagTypes, LaunchDarklyService } from '@shared/services/launch-darkly.service';

export function flagGuard(flagName: flagTypes, fallbackUrl: string): CanActivateFn {
  return async () => {
    const launchdarklyService = inject(LaunchDarklyService);
    const isFlagon = launchdarklyService.isFlagOn(flagName);
    const router: Router = inject(Router);

    async function ensureFlagsReady(): Promise<void> {
      if (!launchdarklyService.flagsReady()) {
        await new Promise<void>((resolve) => {
          const checkFlagsReady = () => {
            if (launchdarklyService.flagsReady()) {
              resolve();
            } else {
              setTimeout(checkFlagsReady, 50);
            }
          };
          checkFlagsReady();
        });
      }
    }

    await ensureFlagsReady();

    return isFlagon() || router.navigate([fallbackUrl]);
  };
}
