@if (viewOverviewLink) {
  <sss-navbar-menu-item
    icon="cui-scania-fms-overview"
    routerLinkActive="active"
    label="{{ 'Overview' }}"
    culture="en-GB"
  >
    <a class="sss-navbar-menu-nav-link" routerLink="/overview">{{ 'Overview' | translate }}</a>
  </sss-navbar-menu-item>
}
@if (ViewChargingDashboard$ | async) {
  <sss-navbar-menu-item
    icon="cui-scania-fms-overview"
    routerLinkActive="active"
    label="{{ 'Dashboard' }}"
    culture="en-GB"
  >
    <a class="sss-navbar-menu-nav-link" routerLink="/my-electric-vehicles">{{
      'ChS_YourElectricVehicles' | translate
    }}</a>
  </sss-navbar-menu-item>
}
@if (viewPublicCharging()) {
  <sss-navbar-menu-item [ngClass]="isActived()" icon="cui-scania-charging" label="{{ 'Scania Charging Access' }}">
    <a class="sss-navbar-menu-nav-link" routerLink="/charging-management">{{ 'Scania Charging Access' }}</a>
  </sss-navbar-menu-item>
}
@if (viewDepartureScheduling()) {
  <sss-navbar-menu-item
    icon="cui-scania-fms-service-coverage"
    routerLinkActive="active"
    label="{{ 'Departure scheduling' }}"
  >
    <a class="sss-navbar-menu-nav-link" routerLink="/departure-scheduling">{{
      'ChS_DepartureScheduling' | translate
    }}</a>
  </sss-navbar-menu-item>
}
@if (viewChargingSettings$ | async) {
  <sss-navbar-menu-item
    icon="cui-sdds-scania-settings"
    routerLinkActive="active"
    label="{{ 'Inställningar' | translate }}"
  >
    <a class="sss-navbar-menu-nav-link" routerLink="/settings">{{ 'ChS_ChargingNotifications' | translate }}</a>
  </sss-navbar-menu-item>
}
@if (viewChargingDashboardFunc$ | async) {
  <sss-navbar-menu-item
    icon="cui-sdds-scania-truck"
    routerLinkActive="active"
    label="{{ 'ChS_ChargingSessions' | translate }}"
  >
    <a class="sss-navbar-menu-nav-link" routerLink="/charging-sessions">{{ 'ChS_ChargingSessions' | translate }}</a>
  </sss-navbar-menu-item>
}

@if (viewMap()) {
  <sss-navbar-menu-item
    icon="cui-scania-fms-locations"
    routerLinkActive="active"
    label="{{ 'Map of charging stations' | translate }}"
  >
    <a class="sss-navbar-menu-nav-link" routerLink="/map">{{ 'Map of charging stations' | translate }}</a>
  </sss-navbar-menu-item>
}

@if (viewAdmin()) {
  <sss-navbar-menu-item icon="cui-sdds-scania-settings" routerLinkActive="active" label="{{ 'Admin' | translate }}">
    <a class="sss-navbar-menu-nav-link" routerLink="/admin">{{ 'Developer Admin' | translate }}</a>
  </sss-navbar-menu-item>
}

<!-- <sss-navbar-menu-item icon="cui-scania-fms-service-coverage" routerLinkActive="active" label="CPOI">
  <a class="sss-navbar-menu-nav-link" routerLink="/cpoi">CPOI (demo)</a>
</sss-navbar-menu-item> -->
