import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';

import { environment } from './environments/environment';

import { applyPolyfills } from '@myscania/scope/dist/loader';
import { defineCustomElements as scope } from '@myscania/scope/dist/loader';
import { defineCustomElements as chscomp } from '@chs/components/loader';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideRouter, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import appRoutes from './app/app-routes';
//
import { provideServiceWorker } from '@angular/service-worker';
import { defineCustomElements as tegel } from '@scania/tegel/loader';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withFetch, withInterceptors, withJsonpSupport } from '@angular/common/http';
import { AppCustomTranslateParser, createTranslateLoader } from '@core/custom-translate-parser';
import { httpErrorInterceptor } from '@core/interceptors/error-interceptor';
import { authInterceptor } from '@core/interceptors/auth.interceptor';
import { KeycloakService } from '@core/services/auth/keycloak.service';

tegel(window);

applyPolyfills().then(() => {
  scope();
});
chscomp();

if (environment.production) {
  enableProdMode();
}

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<void> {
  return () => keycloak.init();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideRouter(appRoutes, withViewTransitions(), withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    provideHttpClient(withJsonpSupport(), withFetch(), withInterceptors([httpErrorInterceptor, authInterceptor])),
    importProvidersFrom(
      ToastrModule.forRoot({
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }),
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        defaultLanguage: 'en-GB',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        parser: {
          provide: TranslateParser,
          useClass: AppCustomTranslateParser,
        },
      }),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [KeycloakService],
      multi: true,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production, //!isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
}).catch((err) => console.error(err));
